<!-- =========================================================================================
    File Name: DataCard.vue
    Description: Card con titulo subtitulo y un icono a la derecha
========================================================================================== -->

<template>
    <div>
        <vx-card :card-background="background" class="mt-base">
          <div class="vx-row">
            <template v-if="icon">
              <div class="vx-col w-2/3">
                <h1 :class="textColor">{{title}}</h1>
                <p :class="textColor+' mt-1 text-lg'">{{subtitle}}</p>
              </div>
              <div class="vx-col w-1/3">
                  <feather-icon :icon="icon" class="p-5 rounded-full text-white" :style="{background: `rgba(var(--vs-${color}),1)`}"></feather-icon>
              </div>
            </template>
            <div v-else class="vx-col w-full">
              <h1 :class="textColor">{{title}}</h1>
              <p :class="textColor+' mt-1 text-lg'">{{subtitle}}</p>
            </div>
          </div>
        </vx-card>
    </div>
</template>

<script>
export default {
  name  : 'data-card',
  props : {
    title  : { type: String, default: "" },
    subtitle : { type: String, default: "" },
    icon: { type: String, default: null },
    background : { type: String, default: "" },
    textColor: {
      type: String,
      required: false,
      default: "black"
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>