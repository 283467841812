<template>
    <div id="loan_card_detail" class="mt-base mb-base loan-card-detail">
        <div class="vx-col w-full">
            <img :src="icons[getIcon()]" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4" class="cursor-pointer text-accent align-self-felx-start mb-6" />
            <h4 class="mb-2 bold extra-bold">
                <slot name="title"></slot>
            </h4>
            <p class="mb-base">
                <slot name="desc"></slot>
                <small class="bold" v-if="showAllDocumentsCompletedNotif">
                    <br>
                    <br>Estamos revisando tu crédito, te avisaremos si ocupamos más información.
                </small>
            </p>
        </div>
        <vs-button @click.stop="requestLoan()" :color="hasLoan ? 'primary' : '#C8C8C8'" :class="hasLoan ? 'pulse-success' : 'hover-primary'">
            {{ buttonText }}
        </vs-button>
    </div>
</template>

<script>
export default {
    name: "LoanCardDetail",
    props: {
        loanType: String|Number,
        loan: Object
    },
    data: () => ({
        icons: {
            'MoneyXLIcon' : require("@assets/images/loan-onboarding/money-bag-xl-icon.svg"),
            'CarIcon' : require("@assets/images/loan-onboarding/car-icon.svg"),
            'SolarPanelIcon' : require("@assets/images/loan-onboarding/solar-panel-icon.svg")
        },
    }),
    computed: {
        hasLoan() {
            return this.loan != null;
        },
        loanId() {
            return this.loan != null ? this.loan.id : null;
        },
        buttonText() {
            let btnText = "Solicitar";
            if(this.hasLoan) { // Sí ya tiene solicitud
                if(this.loan.has_offer && this.loan.credit_acceptance_date != null) { // Sí tiene una oferta y no la aceptado.
                    btnText = "Continuar";
                } else if(this.loan.has_offer && this.loan.credit_acceptance_date == null)  {
                    btnText = "Ver oferta";
                } else {
                    btnText = "Continuar";
                }
            }

            return btnText;
        },
        showAllDocumentsCompletedNotif() {
            if(this.hasLoan) {
                return this.loan.documentation_complete_date != null;
            } else {
                return false;
            }
        },
        isPF() {
            return this.UserPersonType == 1 || this.UserPersonType == 2;
        },
    },
    methods: {
        async requestLoan() {
            if(this.loanId != null) {
                await this.$router.replace({name: 'applicantLoanDetail', params: { id: this.loanId }});
            } else {
                // Sí es un PYME y es PF, avisar que le van a pedir syntage.
                if(this.loanType == 3 && this.isPF) {
                    this.$vs.dialog({
                        type: 'confirm',
                        color: 'warning',
                        title: 'Solicitar Crédito PYME',
                        text: `Para poder solicitar este crédito es necesario que cuentes con el régimen de <<Persona Física con Actividad Empresarial>>, ya que más adelante se validará que cuentes con dicho régimen fiscal.`,
                        acceptText: "Continuar",
                        cancelText: 'Cancelar',
                        accept: this.doStartLoanRequest
                    });
                } else {
                    await this.$router.replace({name: 'loanRequest', params: { loanTypeProp: this.loanType }});
                }
            }
        },
        async doStartLoanRequest()
        {
            await this.$router.replace({name: 'loanRequest', params: { loanTypeProp: this.loanType }});
        },
        getIcon() {
            let icon = "";
            if(this.loanType == 1) {
                icon = 'SolarPanelIcon';
            } else if(this.loanType == 2) {
                icon = 'CarIcon';
            } else {
                icon = "MoneyXLIcon";
            }

            return icon;
        }
    }
}
</script>

<style>
.loan-card-detail {
    border: 2px solid #C8C8C8;
    border-radius: 10px;
    padding: 3rem 3.5rem 3rem 3.5rem;
    text-align: center;
    min-height: 90%;
    position: relative;
}

.loan-card-detail button {
    position: absolute;
    bottom: 5% ;
    transform: translate(-50%, -50%);
}

.hover-primary:hover {
    background-color: #28de18 !important;
    color: white !important;

}
</style>