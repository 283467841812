<template>
  <div>
    <vx-card class="mt-3">
      <div slot="no-body">
        <div class="vx-row m-0 p-0">
          <swiper :options="swiperOptions" class="swiper" ref="swiperTop"
                  @slideChangeTransitionStart="handleSwiperSlideChangeTransitionStart">
            <swiper-slide v-for="(img, idx) in images" :key="idx" class="img-slider" >
              <vue-load-image class="horizontal-alignment">
                <img slot="image" class="img-responsive rounded-top-img" :src="img.url" alt="banner">
                <img slot="preloader" :src="loader" alt="loader" />
                <img slot="error" :src="placeholder" alt="placeholder" class="img-responsive rounded-top-img" />
              </vue-load-image>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <div class="vx-row p-6" v-if="currentProject !== null">
          <div class="vx-col w-full mb-1">
            <p class="text-lg bold mb-2"><span class="color-primary">{{ currentProject.alias }}</span> ya es un girasol.</p>
            <p class="text-sm">Descubre los últimos proyectos que han crecido dentro de la Red. Consulta los datos más relevantes de cada uno. </p>
          </div>
          <div class="vx-col w-1/4 mt-4 xxl:text-lg md:text-sm sm:text-sm">
            <p >Ahorro mensual</p>
            <p class="info-border-inline bold">{{ currentProject.savingFormat }}</p>
          </div>
          <div class="vx-col w-1/4 mt-4 xxl:text-lg md:text-sm sm:text-sm">
            <p >Paneles Solares</p>
            <p class="info-border-inline bold">{{ currentProject.panels }}</p>
          </div>
          <div class="vx-col w-1/4 mt-4 xxl:text-lg md:text-sm sm:text-sm">
            <p >Impacto Ambiental</p>
            <p class="info-border-inline bold">{{ currentProject.tons }} Ton. CO<sub>2</sub></p>
          </div>
          <div class="vx-col w-1/4 mt-4 xxl:text-lg md:text-sm sm:text-sm">
            <p >Inversionistas</p>
            <p class="info-border-inline bold">{{ currentProject.investors }}</p>
          </div>
        </div>
      </div>
    </vx-card>
    <div class="con-upload vx-upload">
      <vx-view-upload v-if="showImage" :src="currentUrl" @on-close-view="closeImage()" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VxViewUpload from "@upload/VxViewUpload";
import VueLoadImage from 'vue-load-image'
import formatHelper from "@mixins/formatHelper";
export default{
  name: 'ApplicantInfoProjectsCard',
  props: {
    projects: {
      type: Array,
      required: true,
      default: [],
    },
  },
  mixins: [formatHelper],
  components: {
    Swiper,
    SwiperSlide,
    VxViewUpload,
    VueLoadImage
  },
  data() {
    return {
      //loader: require('@assets/images/carousel/loader.gif'),
      loader: require('@assets/images/carousel/loader-green.gif'),
      placeholder: require('@assets/images/pages/placeholder-image.png'),
      showImage: false,
      currentName: null,
      currentUrl: null,
      activeIndex: 0,
      swiperOptions: {
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  computed: {
    images(){
      if(this.projects.length < 1){
        return  [];
      }
      let imgs = [];
      this.projects.forEach(f => {
          //let url = `${this.ApiDomain}/storagev3/documents/view_document_file/310/user/5421?access_token=${this.AccessToken}`;
          //imgs.push({index: f.id, url: url, filename: "--"});
        if(f.image != null){
          let url = `${this.ApiDomain}/storagev3/documents/view_document_file/${f.image.id}/project/${f.pid}?access_token=${this.AccessToken}`;
          imgs.push({url: url, filename: f.image.storage_file});
        }
        else {
          imgs.push({url: this.placeholder, filename: "error"});
        }
      });
      return imgs;
    },
    currentProject(){
      if(this.projects.length < 1){
        return null;
      }
      const p = this.projects[this.activeIndex];
      return {
        alias: p.alias,
        panels: this.roundedFormat(p.impacts.find(f => f.pref === 4).value, 2),
        tons: this.roundedFormat(p.impacts.find(f => f.pref === 0).value, 2),
        investors: p.investors,
        savingFormat: this.explicitMoneyFormat(p.saving_per_year/12, 0),
        days: p.days
      };
    },
  },
  methods: {
    async showImageDetail(img){
      this.currentName = img.filename;
      this.currentUrl = img.url;
      this.showImage = true;
    },
    async handleSwiperSlideChangeTransitionStart(){
      const index = this.$refs.swiperTop.swiperInstance.activeIndex;
      if(index < this.projects.length){
        this.activeIndex = index;
      }
    },
    async closeImage(){
      this.showImage = false;
    }
  }
}
</script>

<style>

</style>