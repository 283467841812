<!-- =========================================================================================
    File Name: ProjectCard.vue
    Description: Card con datos de un proyecto fondeado o en fondeo
========================================================================================== -->
<template>
  <div :class="'project-card ' + (project.fund == 1 ? ' fund mt-base' : ' mt-base')">
    <div class="project-card-img-section">
      <vue-load-image>
        <img slot="image" :src="getCoverUrl" alt="cover">
        <img slot="error" :src="placeholder" alt="placeholder" />
      </vue-load-image>
    </div>
    <div class="project-card-content">
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <h2 class="text-center">{{project.name}}</h2>
          <label class="text-center">{{getProjectName}}</label>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full text-center">
          <!-- <label v-if="project.fund == 1">Esta semilla completó:</label> -->
          <!-- <label v-else>Esta semilla lleva:</label> -->
          <div class="project-info-pill pill-white">
            <span v-if="project.fund == 1">100%</span><span v-else>{{ ((project.invested/(project.financed_amount))*100).toFixed(0) }}%</span> DE {{mxnFormat(project.financed_amount)}}
          </div>
          <label for="">Avance en Fondeo</label>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-2/3 pr-1">
          <div class="project-info-pill pill-black">
            {{ project.fund == 1 ? project.investors : mxnFormat(project.financed_amount - project.invested) }}
          </div>
          <label>{{ project.fund == 1 ? "Inversionistas":"Faltantes" }}</label>
        </div>
        <div class="vx-col w-1/3 pl-1">
          <div class="project-info-pill pill-black">
            {{ project.term / 12 }}
          </div>
          <label>Años</label>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-2/3 pr-1">
          <div class="project-info-pill pill-black">
            {{ decimalPercentFormat(project.anual_interest) }} anual
          </div>
          <label>Tasa de Rendimiento</label>
        </div>
        <div class="vx-col w-1/3 pl-1">
          <div class="project-info-pill pill-black text-white no-border mb-1 py-1" :class="`bg-${qualificationCode(project.qualification)} border-${qualificationCode(project.qualification)}`">
              {{project.qualification}}
          </div>
          <label>Calificación</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import VueLoadImage from 'vue-load-image'
import qualificationsHelper from '@mixins/qualificationsHelper';
import formatHelper from '@mixins/formatHelper';
import portadasProyectosHelper from '@mixins/portadasProyectosHelper'
export default  {
  name: 'ProjectFundingCard',
  props: ['project', 'isFunded'],
  components: {VueLoadImage},
  mixins: [formatHelper,qualificationsHelper,portadasProyectosHelper],
  data() {
    return {
      placeholder:"",
      coverUrl: "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/",
      projectsUrl: "https://cdn.redgirasol.com/projects/"
    }
  },
  methods: {
    goToSignUp() {
      window.location.href = `${this.AppURL}/info-registro`;
    },
  },
  computed: {
    getCoverUrl() {
      this.placeholder = this.getCoverLoanType(this.project.loan_type);
      if (this.isFunded) {
        return `${this.ApiDomain}/storagev3/documents/view_document_file/${this.project.cover_file_id}/project/${this.project.id}?access_token=${this.publicAccessToken()}`;
      } else {
        if (this.project.loan_type == 4) {
          return `${this.projectsUrl}${this.project.id}/${this.project.public_cover_image}`;
        } else {
          if (this.project.public_cover_image !== null) {
            return `${this.projectsUrl}${this.project.id}/${this.project.public_cover_image}`;
          } else if (this.project.programInfo) {
            return this.coverUrl + this.project.programInfo.public_cover_image_seed;
          } else if (this.project.project_category_id !== null) {
            const ods = JSON.parse(this.project.category.ods);
            return this.coverUrl + ods.cover;
          }
        }
      }
    },
    getProjectName() {
      let name = "No definido"
      if(this.project.category) {
        name = this.project.category.name;
      } 
      return name;
    }
  }
}
</script>
<style lang="scss">

.skinny {
    margin-right: 0;
    margin-left: 0;
}

.project-card{
  max-width: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 5px 25px -10px #3b3a3e;
  line-height: normal;
  // margin-bottom: 30px;
  text-align: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.project-card .project-card-content{
  padding: 0 15px 15px 15px;
}
.project-card .project-card-content label{
  font-size: 13px;
  margin: 0;
}
.project-card .row{
  margin-bottom: 10px;
}
.project-card .project-card-img-section{
  position: relative;
}
.project-card.fund .project-card-img-section{
  position: relative;
}
.project-card .project-card-img-section img{
  width: 100%;
  height: 175px;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
.project-card .project-card-img-section .show-more-button{
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: #3b3a3e;
  color: white;
  margin: auto;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  cursor: pointer;
}
.project-card .project-card-img-section .show-more-button label{
  font-size: 35px;
  margin: 0 auto;
  line-height: 40px;
  cursor: pointer;
  font-weight: 500;
}
.project-card h2{
  margin: 10px 0 0 0;
  font-family: 'gilroyextrabold';
  white-space: nowrap;
  text-overflow: ellipsis;
}
.project-card label{
  font-weight: normal;
}
.project-card .project-info-pill{
  width: 100%;
  border: solid 2px #3b3a3e;
  border-radius: 125px;
  padding: 5px 0;
  font-family: 'gilroyextrabold';
  font-size: 20px;
}
.project-card a .project-info-pill{
  color: white;
}
.project-card a .project-info-pill:hover{
  color: white;
}
.project-card .project-info-pill.pill-black{
  color: white;
  background-color: #3b3a3e;
}
.project-card .project-info-pill.pill-button {
  color: white;
  background-color: #28DE18;
  border: solid 2px #28DE18;
}
.project-card .project-info-pill span{
  font-family: 'gilroyextrabold';
  font-size: 20px;
  color: #28DE18;
}
.project-card.fund .project-info-pill span{
  color:#33b2c1;
}
.project-card .project-info-pill.pill-qualification{
  border: none;
  padding: 7px 0;
}
</style>