<template>
  <div id="inicio">
    <!-- PROYECTO EN ESTATUS 1,2 o 3 PENDIENTE-->
    <!-- <div v-if="focusProject.status === 1 || focusProject.status === 2 || focusProject.status === 3">
      <big-title-color variant-class="dark">
        <template v-slot:text>¡Bienvenid@ a la <span>Red</span>!</template>
      </big-title-color> 
      <div class="vx-row mt-base">
        <div v-if="focusProject.status === 1 || focusProject.status === 2" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <info-card
            variant-class="clear"
            button-text="Completar mi solicitud"
            :needs-button="true">
            <template v-slot:text><span>¡Obtén tu </span>crédito</template>
          </info-card>
        </div>

        <div v-if="focusProject.status === 3 && !focusProject.documentation_complete_date" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <info-card
            variant-class="clear"
            button-text="Completar información"
            :needs-button="true">
            <template v-slot:text><span>¡Crédito </span>pre-aprobado!</template>
          </info-card>
        </div>

        <div v-if="focusProject.status === 1 || focusProject.status === 2 || (focusProject.status === 3 && !focusProject.documentation_complete_date)" class="vx-col lg:w-2/3 md:w-1/2 sm:w-full w-full">
          <youtube-video-widget :media="videoSolicitud" title="¿Cómo llenar la solicitud?"/>
        </div>

        <div  v-if="focusProject.status === 3 && focusProject.documentation_complete_date" class="vx-col lg:w-1/3 md:w-1/2 sm:w-full w-full">
          <info-card
            variant-class="clear"
            button-text="Completar información"
            :needs-button="false">
            <template v-slot:text><span>Solicitud en </span>revisión</template>
          </info-card>
        </div>
      </div>
    </div> -->

    <big-title-color v-if="focusProject.status === 4 && !focusProject.finance.credit_acceptance_date" variant-class="primary">
      <template v-slot:text>¡Bienvenid@ al crédito de <span>RedGirasol</span>!</template>
    </big-title-color>
    <big-title-color v-else variant-class="primary">
      <template v-slot:text>¡Bienvenid@ a la <span>Red</span>!</template>
    </big-title-color>
    <div class="mt-base"></div>

    
    <!-- PANEL DE CUMPLIMIENTO -->
    <compliance-banner />

    <!-- PROYECTO EN ESTATUS 4 (APROBADO) -->
    <div v-if="focusProject.status === 4">
      <!-- PROYECTO EN ESTATUS 4 SIN credit_acceptance_date -->
      <div v-if="!focusProject.finance.credit_acceptance_date">
        <big-title-color variant-class="clear">
          <template v-slot:text>Tu crédito {{focusProjectAliasId}} está <span>aprobado</span>.</template>.
        </big-title-color>
        <vx-card class="mt-base" title="Estas son las condiciones de tu crédito.">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p>
                Antes de aceptar tu crédito consulta las condiciones finales y prepárate para comenzar a ahorrar en tu recibo de luz
                ¡gracias a la energía limpia! <br> Si tienes alguna duda contacta a tu ejecutivo de cuenta o al instalador de tu proyecto.
                Si quieres conocer más proyectos semilla como el tuyo da clic<span><router-link class="ml-1 inline-link-primary text-dark" to="" @click.native="showOtherProjects=true">aquí.</router-link></span>.
              <br>
              </p>
              <vs-button color="dark" class="mt-5 ml-2" @click="acceptCredit">Aceptar crédito</vs-button>
            </div>
          </div>
        </vx-card>
        <div class="vx-row">
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
              <data-card :title="decimalPercentFormat(focusProject.finance.deposit_percentage,2)" subtitle="Enganche" icon="DollarSignIcon" />
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
              <data-card :title="focusProject.finance.term+''" subtitle="Meses" icon="ClockIcon" />
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
              <data-card :title="decimalPercentFormat(focusProject.finance.annual_interest_percentage,2)" subtitle="Tasa" icon="PercentIcon" />
          </div>
        </div>
        <div class="vx-row">
          <div v-if="focusProject.finance.delivery_term > 0" class="vx-col lg:w-1/4 md:w-1/2 w-full">
              <data-card :title="explicitMoneyFormat(focusProject.finance.symbolic_payment)" :subtitle="'Pago Entrega ('+focusProject.finance.delivery_term+' pagos)'" />
          </div>
          <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
              <data-card
                :title="explicitMoneyFormat(focusProject.finance.monthly_payment,2)"
                :subtitle="'Pago Mensual ('+ (focusProject.finance.term - focusProject.finance.delivery_term) +' pagos)'"
                background="dark"
                textColor="white"
                />
          </div>
        </div>

        <!-- CARD FACTURACION DE RETENCIONES SOLO PM Y PFAE -->
        <vx-card v-if="focusProject.finance.crowdfunding_loan_type == 2 && (UserPersonType == 0 || UserPersonType == 1)" class="mt-base" title="Facturación">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p>
                En RedGirasol queremos apoyarte con tu proceso de facturación, por lo que podrás indicar si requieres habilitar el modulo,<br>
                para posteriormente cargar tus certificados de sello digital y asi poder realizar la generación de tus facturas de manera automática.<br>
                <br>
                Si deseas saber más sobre la facturación en la red da clic 
                <a
                  class="inline-link"
                  target="_blank"
                  rel="noopener"
                  :href="$sanitizeUrl(invoiceUrl)"
                  >aquí
                </a> o sobre el procedimiento para activar esta funcion da clic 
                <a
                  class="inline-link"
                  target="_blank"
                  rel="noopener"
                  :href="$sanitizeUrl(invoiceRetentionsUrl)"
                  >aquí
                </a>
              </p>
              <br>
              <label class="bold">Desactivar/Activar</label>
              <vs-switch color="success" v-model="clientInvoiceRequired" />
            </div>
          </div>
        </vx-card>

        <vx-card class="mt-base" title="¡Comienza a ahorrar en tu recibo de luz!">
          <div class="vx-row">
            <div class="vx-col w-full">
              <p>
                ¿Estás list@ para aceptar tu crédito? Prepárate para comenzar a ahorrar en tu recibo de luz ¡gracias a la energía limpia!<br>
                Si quieres conocer más proyectos semilla como el tuyo da clic<span><router-link class="ml-1 inline-link-primary text-dark" to="" @click.native="showOtherProjects=true">aquí.</router-link></span>.
              <br>
              </p>
              <vs-button color="dark" class="mt-5 ml-2" @click="acceptCredit">Aceptar crédito</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
      <!-- PROYECTO EN ESTATUS 4 CON credit_acceptance_date SIN initial_payment_date-->
      <!-- <div v-else-if="!focusProject.initial_payment_date">
        <div v-if="mainComplianceStatus!=='completed'">
          <vx-card
          class="mt-base"
          card-background="dark"
          title="¡No olvides verificar tu identidad!"
          title-color="white"
          >
            <p style="color: white;">
                En la Red, es importante verificar que tus datos concuerden con tu identidad. Verifícala lo más pronto posible, ya que de lo contrario 
                tu proyecto no podrá publicarse a fondeo y no podremos recabar los fondos para comenzar con la instalación de tu sistema. <br>
                Es importante que verifiques tu identidad, de lo contrario <strong>no podrás consultar tu ficha de pago única para realizar tu pagos</strong>.
            </p>
            <vs-button class="mt-4" type="border" @click="goToVerification()">Verificar mi identidad</vs-button>
          </vx-card>
          <div class="mt-base"></div>
        </div>
        <big-title-color variant-class="clear">
          <template v-slot:text>Aceptaste el crédito {{focusProjectAliasId}}.</template>.
        </big-title-color>
        <vx-card
          class="mt-base"
          card-background="primary"
          :title="'La aprobación de tu crédito tiene una vigencia de '+initialPaymentRemainingDays+' días'"
          title-color="white"
          >
          <p style="color: white;">
            Si no se formaliza en este periodo, es posible que debamos actualizar información para continuar.
          </p>
        </vx-card>
        <vx-card v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false"
        class="mt-base"
        title="No olvides cargar tus sellos">
          <p>
              Recuerda que para poder generar tus facturas deberás contar con tu <strong>Certificado de Sello Digital (CSD)</strong> correspondiente, por lo tanto no olvides cargarlo antes de realizar tu pago para que se puedan generar tus facturas.              
              puedes darlos de alta accediendo al siguiente<span><router-link class="ml-1 inline-link text-dark" to="" @click.native="goToUploadCsds">enlace.</router-link></span>.
          </p>
        </vx-card>
        <payment-options v-if="mainComplianceStatus==='completed'"></payment-options>
      </div> -->
      <!-- PROYECTO EN ESTATUS 4 CON credit_acceptance_date CON initial_payment_date-->
      <div v-else>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div v-if="mainComplianceStatus!=='completed'">
              <vx-card
              card-background="dark"
              title="¡No olvides verificar tu identidad!"
              title-color="white"
              >
                <p style="color: white;">
                    En la Red, es importante verificar que tus datos concuerden con tu identidad. Verifícala lo más pronto posible, ya que de lo contrario
                    tu proyecto no podrá publicarse a fondeo y no podremos recabar los fondos para comenzar con la instalación de tu sistema.
                </p>
                <vs-button class="mt-4" type="border" @click="goToVerification()">Verificar mi identidad</vs-button>
              </vx-card>
              <div class="mt-base"></div>
            </div>
            <div>
              <vx-card
              class="mt-base"
              card-background="primary"
              title="Tu proyecto está por publicarse."
              title-color="white"
              >
                <p style="color: white;">
                  Por el momento estamos preparando los últimos detalles para que tu proyecto semilla
                  se publique a fondeo y nuestra comunidad de inversionistas comience a aportar los fondos necesarios para su instalación y entrega.
                  ¡En la Red el sol brilla para todos!
                </p>
              </vx-card>
            </div>
            <vx-card v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false"
            class="mt-base"
            title="No olvides cargar tus sellos">
              <p>
                  Recuerda que para poder generar tus facturas deberás contar con tu <strong>Certificado de Sello Digital (CSD)</strong> correspondiente, por lo tanto no olvides cargarlo antes de realizar tu pago para que se puedan generar tus facturas.              
                  puedes darlos de alta accediendo al siguiente<span><router-link class="ml-1 inline-link text-dark" to="" @click.native="goToUploadCsds">enlace.</router-link></span>.
              </p>
            </vx-card>
            <vx-card
            class="mt-base"
            title="Descubre algunos proyectos que actualmente están en fondeo."
            >
            <p class="text-md">
                A continuación podrás encontrar algunos proyectos como el tuyo que también están muy cerca de comenzar a ahorrar en su recibo de luz.<br>
                En la Red tenemos tienditas, hogares, restaurantes y más semillas que muy pronto crecerán en ¡grandes girasoles!
            </p>
            </vx-card>
            <vs-row vs-w="12">
                <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full" v-for="(proj, idx) in fundingProjects" v-bind:key="idx">
                  <project-card :project="proj"/>
                </div>
            </vs-row>
          </div>
        </div>
      </div>
      <!-- popup aceptacion de credito -->
      <vs-popup
        :active.sync="acceptModalActive"
        title="¡Crédito aceptado!"
        class="header-primary"
      >
        <div class="pt-1 p-4" :style="{ backgroundImage: clearBg }">
          <h2 class="mb-2">
            ¡Felicidades! El sol está por <span class="text-primary">brillar</span> más fuerte que nunca!
          </h2>
          <template v-if="mainComplianceStatus!=='completed'">
            <p>
              ¡Listo! Has aceptado tu crédito con RedGirasol. 
              Para continuar con el proceso será necesario que verifiques tu identidad. 
              Sigue las indicaciones que se presentan a continuación. 
              ¡Ya estás muy cerca de comenzar a ahorrar!
            </p>
            <vs-button class="mt-base" @click="goToVerification()">Verificar mi identidad</vs-button>
          </template>
          <template v-else>
            <p>
              ¡Listo! Has aceptado tu crédito con RedGirasol.
              ¡Ya estás muy cerca de comenzar a ahorrar!
            </p>
            <vs-button class="mt-base" @click="reloadPage()">Continuar</vs-button>
          </template>
        </div>
      </vs-popup>
      <!-- popup otros creditos -->
      <vs-popup
          :active.sync="showOtherProjects"
          title="Nuestros girasoles"
          class="extended-popup header-primary"
        >
        <div class="pt-1 p-4">
          <applicant-info-projects-card :projects="deliveredProjects" />
        </div>
      </vs-popup>
    </div>

    <!-- PROYECTO EN ESTATUS 5 (EN FONDEO) -->
    <div v-if="focusProject.status === 5">
      <div class="vx-row mt-base">
            <div class="vx-col w-full">
              <big-title-color variant-class="clear">
                <template v-slot:text>Tu proyecto {{focusProjectAliasId}} está en <span>fondeo</span>.</template>.
              </big-title-color>
              <vx-card v-if="allData.pendingContracts > 0" class="mt-base" title="¡Agiliza la instalación de tu proyecto!">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p>
                      A partir de este momento ya puedes comenzar con la firma de contratos para agilizar la instalación y entrega de tu proyecto.<br>
                      Da clic a continuación para firmar o contacta a tu instalador para conocer más sobre este proceso.<br>
                    </p>
                    <vs-button color="primary" class="mt-5" @click="goToSignContracts">Firmar contratos</vs-button>
                  </div>
                </div>
              </vx-card>
              <vx-card v-else class="mt-base" title="En la Red el sol brilla para todos.">
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p>
                      ¿Quieres que tus amigos o familiares participen en tu proyecto? ¡Invítalos a invertir!<br>
                      Da clic en cualquiera de los 3 iconos que se presentan a continuación conforme a la red 
                      social de tu preferencia, ya sea facebook, twitter o whatsapp e invítalos a invertir con
                      impacto con un mensaje personalizado.<br>
                    </p>
                    <div class="flex flex-wrap mt-5">
                      <share-network
                          v-for="network in networks"
                          :network="network.network"
                          :key="network.network"
                          :url="sharing.url"
                          :title="sharing.title"
                          :description="sharing.description"
                          :quote="sharing.quote"
                      >
                          <vs-button radius :color="network.color" class="ml-2" icon-pack="feather" :icon="network.icon"></vs-button>
                      </share-network>
                    </div>
                  </div>
                </div>
              </vx-card>
              <div class="vx-row">
                <div v-if="allData.pendingContracts > 0" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <info-card
                    variant-class="primary"
                    :needs-button="true"
                    button-text="Firmar contratos"
                    @on-button-action="goToSignContracts"
                    >
                    <template v-slot:text>¡No te olvides de <span>firmar</span>!</template>
                  </info-card>
                </div>
                <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <project-card :project="progressFocusProject"/>
                </div>
                <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <share-card variant-class="clear" :title="sharing.title" :description="sharing.description" :quote="sharing.quote">
                    <template v-slot:text>¡Comparte tu proyecto!</template>
                  </share-card>
                </div>
                <div v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                  <info-card
                      variant-class="dark"
                      :needs-button="true"
                      button-text="Subir"
                      @on-button-action="goToUploadCsds"
                      >
                      <template v-slot:text>¡Sube tus sellos <span>(CSD)</span>!</template>
                    </info-card>                
                </div>
              </div>
            </div>
      </div>
    </div>

    <!-- PROYECTO EN ESTATUS 6 (FONDEADO) -->
    <div  v-if="focusProject.status === 6">
      <div class="vx-row mt-base">
        <div class="vx-col w-full">
          <big-title-color variant-class="clear">
            <template v-slot:text>¡Tu proyecto {{focusProjectAliasId}} está <span>fondeado</span>!</template>.
          </big-title-color>
          <vx-card v-if="allData.pendingContracts > 0" class="mt-base" title="¡Agiliza la instalación de tu proyecto!">
            <div class="vx-row">
              <div class="vx-col w-full">
                <p>
                  A partir de este momento ya puedes comenzar con la firma de contratos para agilizar la instalación y entrega de tu proyecto.<br>
                  Da clic a continuación para firmar o contacta a tu instalador para conocer más sobre este proceso.<br>
                </p>
                <vs-button color="primary" class="mt-5 ml-2" @click="goToSignContracts">Firmar contratos</vs-button>
              </div>
            </div>
          </vx-card>
          <div v-else class="mt-base">
            <vx-card
                class="mt-base"
                title="La instalación de tu proyecto comenzará muy pronto.">
                <p class="text-md">
                  Por el momento, consulta la ficha única de pago en la sección “Realizar pagos” que utilizarás para cubrir tus mensualidades. 
                  Si deseas consultar el plan de pagos de tu crédito podrás hacerlo dando clic en el botón que se encuentra a continuación.
                </p>
                <vs-button color="primary" class="sm:px-base mt-5 px-4" @click="popUpPaymentsActive = true">Plan de pagos</vs-button>
            </vx-card>
          </div>
          <div class="vx-row">
            <div v-if="allData.pendingContracts > 0" class="vx-col xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="primary"
                :needs-button="true"
                button-text="Firmar contratos"
                @on-button-action="goToSignContracts"
                >
                <template v-slot:text>¡No te olvides de <span>firmar</span>!</template>
              </info-card>
            </div>
            <div v-else class="vx-col xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="primary"
                :needs-button="true"
                button-text="Opciones de pago"
                @on-button-action="goToPaymentOptions"
                >
                <template v-slot:text>¿Cómo vas a <span>pagar</span>?</template>
              </info-card>
            </div>
            <div class="vx-col xxl:w-1/4 lg:w-1/2 md:w-1/2 sm:w-full w-full">
              <project-card :project="progressFocusProject"/>
            </div>
            <div v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                  variant-class="dark"
                  :needs-button="true"
                  button-text="Subir"
                  @on-button-action="goToUploadCsds"
                  >
                  <template v-slot:text>¡Sube tus sellos <span>(CSD)</span>!</template>
                </info-card>                
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PROYECTO EN ESTATUS 7 (EN INSTALACION)-->
    <div  v-if="focusProject.status === 7">
      <div class="vx-row mt-base">
        <div class="vx-col w-full">
          <big-title-color variant-class="clear">
            <template v-slot:text>Tu proyecto {{focusProjectAliasId}} está en <span>instalación</span>.</template>.
          </big-title-color>
          <div class="mt-base">
            <big-title-color v-if="showPaymentReminder" variant-class="dark">
              <template v-slot:text>Paga tu primer mensualidad antes del <span>{{midDateFormat(allData.firstPaymentDate)}}</span>.</template>.
            </big-title-color>
            <big-title-color v-else variant-class="dark">
              <template v-slot:text>Tu próximo pago es de <span>{{explicitMoneyFormat(allData.totalDue,2)}}</span></template>.
            </big-title-color>
          </div>
          <vx-card
              class="mt-base"
              title="No olvides realizar tu pago.">
              <p class="text-md">
                Consulta tu ficha única de pago en la sección “Realizar pagos” para elegir la forma
                de pago que mejor se adecue a tus necesidades. Si deseas consultar el plan de pagos 
                de tu crédito podrás hacerlo dando clic en el botón que se encuentra a continuación.
              </p>
              <vs-button color="primary" class="sm:px-base mt-5 px-4" @click="popUpPaymentsActive = true">Plan de pagos</vs-button>
          </vx-card>
          <div class="vx-row">
            <div v-if="allData.pendingContracts > 0" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="primary"
                :needs-button="true"
                button-text="Firmar contratos"
                @on-button-action="goToSignContracts"
                >
                <template v-slot:text>¡No te olvides de <span>firmar</span>!</template>
              </info-card>
            </div>
            <div v-if="allData.pendingFeedback" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="clear"
                :needs-button="true"
                button-text="Responder encuesta"
                @on-button-action="goToApplicantSurvey(focusProject)"
                >
                <template v-slot:text>Opina sobre la <span>instalación</span>.</template>
              </info-card>
            </div>
            <div v-if="focusProject.evidences > 0" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="clear"
                :needs-button="true"
                button-text="Ver evidencias"
                @on-button-action="goToApplicantProjectDetail(focusProject)"
                >
                <template v-slot:text>Evidencias de <span>instalación</span>.</template>
              </info-card>
            </div>
            <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <project-card :project="progressFocusProject"/>
            </div>
            <div v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                  variant-class="dark"
                  :needs-button="true"
                  button-text="Subir"
                  @on-button-action="goToUploadCsds"
                  >
                  <template v-slot:text>¡Sube tus sellos <span>(CSD)</span>!</template>
                </info-card>                
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PROYECTO EN ESTATUS 8 (ENTREGADO) -->
    <div v-if="focusProject.status === 8">
      <div class="vx-row mt-base">
        <div class="vx-col w-full">
          <big-title-color variant-class="clear">
            <template v-slot:text>Tu crédito {{focusProjectAliasId}} está: <span>{{focusProject.credit_status_str}}</span>.</template>.
          </big-title-color>
          <div class="mt-base"></div>
          <big-title-color v-if="allData.totalDue > 0" variant-class="dark">
            <template v-slot:text>Tu próximo pago es de <span>{{explicitMoneyFormat(allData.totalDue,2)}}</span></template>.
          </big-title-color>
          <big-title-color v-else variant-class="dark">
            <template v-slot:text>La fecha de tu próximo pago es el <span>{{midDateFormat(allData.nextPaymentDate)}}</span></template>.
          </big-title-color>

          <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->
          <vx-card
              v-if="user.payment_method != 3"
              class="mt-base"
              title="¡Olvida las preocupaciones y activa el pago automático!"
              title-color="#0F7B0B"
              card-background="#E9F8E8">
              <p class="text-md">
                Mantén un historial de pagos puntual, fortalece tu perfil crediticio y puertas a mejores oportunidades financieras en el futuro. 
                No dejes que los olvidos o el tiempo consumido por los pagos manuales te detengan. 
                ¡Da el paso hacia un camino financiero más simple y exitoso activando el pago automático ahora mismo, es muy sencillo!
              </p>
              <vs-button color="#20B61A" class="sm:px-base mt-5 px-4" @click="goToAutomaticPayments">Activar pago automático</vs-button>
          </vx-card>
          <!-- CARD PARA ACTIVAR LOS PAGOS AUTOMATICOS -->

          <div class="vx-row">
            <div v-if="allData.pendingContracts > 0" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="primary"
                :needs-button="true"
                button-text="Firmar contratos"
                @on-button-action="goToSignContracts"
                >
                <template v-slot:text>¡No te olvides de <span>firmar</span>!</template>
              </info-card>
            </div>
            <div v-if="focusProject.finance.credit_status === al_corriente" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                <info-card
                  variant-class="primary"
                  button-text="Consultar"
                  :needs-button="true"
                  @on-button-action="goToPaymentOptions">
                  <template v-slot:text>Consulta tu ficha de <span>pago</span>.</template>
                </info-card>
            </div>
            <div v-else-if="focusProject.finance.credit_status === en_periodo_gracia" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                <info-card
                  variant-class="primary"
                  button-text="Realizar pago"
                  :needs-button="true"
                  @on-button-action="goToPaymentOptions">
                  <template v-slot:text>¡Evita una <span>penalización</span>!</template>
                </info-card>
            </div>
            <div v-else class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
                <info-card
                  variant-class="primary"
                  button-text="Realizar pago"
                  :needs-button="true"
                  @on-button-action="goToPaymentOptions">
                  <template v-slot:text>¡Cuida tu buró de <span>crédito</span>!</template>
                </info-card>
            </div>
            <div v-if="focusProject.evidences > 0" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="primary"
                :needs-button="true"
                button-text="Ver evidencias"
                @on-button-action="goToApplicantProjectDetail(focusProject)"
                >
                <template v-slot:text>Evidencias de <span>instalación</span>.</template>
              </info-card>
            </div>
            <div v-if="allData.pendingFeedback" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="clear"
                :needs-button="true"
                button-text="Responder encuesta"
                @on-button-action="goToApplicantSurvey(focusProject)"
                >
                <template v-slot:text>Opina sobre la <span>instalación</span>.</template>
              </info-card>
            </div>
            <div v-if="allData.projectMonitor" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                variant-class="blue"
                :needs-button="true"
                button-text="Monitorear"
                @on-button-action="goToApplicantProjectDetail(focusProject)"
                >
                <template v-slot:text>Monitorea tus <span>sistemas</span>.</template>
              </info-card>
            </div>
            <div v-if="clientInvoiceRequired && allData.csdsUploadedAndValid == false" class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full">
              <info-card
                  variant-class="dark"
                  :needs-button="true"
                  button-text="Subir"
                  @on-button-action="goToUploadCsds"
                  >
                  <template v-slot:text>¡Sube tus sellos <span>(CSD)</span>!</template>
                </info-card>                
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Popup para tabla de amortización -->
    <template v-if="focusProject.status === 6 || focusProject.status === 7">
      <vs-popup :active.sync="popUpPaymentsActive" :title="focusProject.alias" class="max-width-popup header-primary">
        <applicant-payments-plan :project-id="focusProject.id" />
      </vs-popup>
    </template>

    
    <!-- CARDS -->
    <div v-if="isMounted" class="vx-row mt-base vx-card m-0 mb-base p-base">
      <div class="vx-col w-full">
        <h1 class="extrabold">Comienza tu solicitud hoy mismo.</h1>
      </div>
      <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 xxl:w-1/3">
        <LoanCardDetail :loanType="3" :loan="getLoan(3)">
          <template v-slot:title>Crédito PYME</template>
          <template v-slot:desc>Crece tu negocio con hasta {{ costFormatNoCents(LoanLimits.pm_max_limit) }}</template>
        </LoanCardDetail>
      </div>
      <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 xxl:w-1/3">
        <LoanCardDetail :loanType="4" :loan="getLoan(4)">
          <template v-slot:title>Crédito Personal</template>
          <template v-slot:desc>Te prestamos desde {{ costFormatNoCents(LoanLimits.min_limit) }} para mejorar tu vida y la del planeta.</template>
        </LoanCardDetail>
      </div>
      <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 xxl:w-1/3">
        <LoanCardDetail :loanType="2" :loan="getLoan(2, 7)">
          <template v-slot:title>Crédito Automotriz</template>
          <template v-slot:desc>Ahórrate la gasolina y cambia al mundo con hasta {{ costFormatNoCents(LoanLimits.max_limit) }} de crédito.</template>
        </LoanCardDetail>
      </div>
      <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 xxl:w-1/3">
        <LoanCardDetail :loanType="1" :loan="getLoan(1, 1)">
          <template v-slot:title>Crédito Solar</template>
          <template v-slot:desc>Obtén hasta {{ costFormatNoCents(LoanLimits.max_limit) }} y ahorra usando energía solar.</template>
        </LoanCardDetail>
      </div>
    </div>

    <!-- LISTA DE CRÉDITOS SOLICITADOS -->
    <!-- <div class="vx-row">
      <LoanTable />
    </div> -->
  </div>
</template>

<script src="/dist/vue-social-sharing.js"></script>
<script>
import { mapState } from "vuex";
import qualificationsHelper from '@mixins/qualificationsHelper';
import { es } from "vuejs-datepicker/dist/locale";
import ProjectCard from '../../layouts/components/cards/ProjectCard.vue';
import DataCard from '../../layouts/components/cards/DataCard.vue';
// import YoutubeVideoWidget from '@components/supplier/widgets/YoutubeVideoWidget.vue';
import formatHelper from '@mixins/formatHelper';
import dateHelper from "@mixins/dateHelper";
import PaymentOptions from "@components/applicant/PaymentOptions.vue";
import currencyHelper from '@mixins/currencyHelper';
import userIdentityHelper from "@/helpers/userIdentityHelper";
import applicantInfoHelper from "@/helpers/applicantInfoHelper";
import ApplicantPaymentsPlan from "@components/applicant/projects/detail/ApplicantPaymentsPlan";
import InfoCard from '../../layouts/components/cards/InfoCard.vue';
import ShareCard from '../../layouts/components/cards/ShareCard.vue';
import ApplicantInfoProjectsCard from "@components/applicant/widgets/ApplicantInfoProjectsCard";
// const tutorialSolcitudLink = "https://youtu.be/19npgHmTUi0";
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import LoanTable from "../applicant/loan-requests/components/LoanTable.vue";
import LoanCardDetail from "@views/applicant/loan-requests/components/home/LoanCardDetail.vue";
import CreditStatusEnum from "@/modules/enums/CreditStatusEnum";

export default {
  mixins: [qualificationsHelper,formatHelper,dateHelper,currencyHelper,userIdentityHelper,applicantInfoHelper, CreditStatusEnum],
  components:{
    // YoutubeVideoWidget,
    DataCard,
    ProjectCard,
    PaymentOptions,
    ApplicantPaymentsPlan,
    InfoCard,
    ShareCard,
    ApplicantInfoProjectsCard,
    ComplianceBanner,
    LoanTable,
    LoanCardDetail
  },
  props: ["id"],
  data() {
    return {
        es: es,
        al_corriente: CreditStatusEnum.AL_CORRIENTE,
        en_periodo_gracia: CreditStatusEnum.EN_PERIODO_GRACIA,
        allData: {
          firstPaymentDate: null,
          focusProject: {
            status:null,
            evidences:0,
            credit_status_str:""
          },
          totalDue: 0,
          pendingContracts: 0, 
          pendingFeedback: false, 
          projectMonitor: false,
          nextPaymentDate: false
        },
        // videoSolicitud: { sources: [{ type: 'video/youtube', src: tutorialSolcitudLink }], poster: 'https://pbs.twimg.com/profile_images/1361659184526233613/hKbOBV7B_400x400.jpg' },        
        fundingProjects: [],
        deliveredProjects: [],
        identity: null,
        status: null,
        processWasStarted: false,
        identityIsCompleted: false,
        isMoral: null,
        mainComplianceStatus: null,
        popUpPaymentsActive: false,
        acceptModalActive: false,
        showOtherProjects: false,
        isMounted: null,
        clearBg: `url(${require('@assets/images/pages/pattern01_sm_clear.jpg')})`,
        sharing: {
            url: 'https://www.redgirasol.com/',
            title: '',
            description: 'En la Red el sol brilla para todos.',
            quote: 'En la Red el sol brilla para todos.',
        },
        networks: [
            { network: 'facebook', name: 'Facebook', icon: 'icon-facebook', color: '#1877f2' },
            { network: 'twitter', name: 'Twitter', icon: 'icon-twitter', color: '#1da1f2' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'icon-phone', color: '#25d366' },
        ],
        invoiceUrl: 'https://blog.redgirasol.com/facturacion-en-la-red/',
        invoiceRetentionsUrl: 'https://blog.redgirasol.com/procedimiento-de-retencion-y-timbrado/',
        contractApplicantMerchantType:4,
        clientInvoiceRequired: false,
        projects: []
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    initialPaymentRemainingDays(){
      let remainingDays=0;
      if (this.focusProject.finance.credit_acceptance_date) {
        const today = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        const acceptance_date = new Date(this.focusProject.finance.credit_acceptance_date);
        let maxLimit = new Date();
        maxLimit.setDate(acceptance_date.getDate() + 29);
        var diffInTime = maxLimit.getTime() - today.getTime();
        remainingDays = Math.round(diffInTime / oneDay);
      }
      return remainingDays;
    },
    progressFocusProject(){
      let project = {};
      let focusProject = this.focusProject; 
      if (focusProject !== null) {
        project = focusProject;
        project.name = focusProject.alias;
      }
      return project;
    },
    showPaymentReminder(){
      let show = false;
      if (this.allData.firstPaymentDate) {
          const today = new Date();
          let firstPayment = new Date(this.allData.firstPaymentDate); 
          if (today <= firstPayment) {
            show = true;
          }
      }
      return show;
    },
    focusProject(){
      return this.allData.focusProject;
    },
    focusProjectAliasId(){
      return this.focusProject.alias+" (ID "+this.focusProject.id+")";
    },
    getLoanTitle() {
      if(this.UserPersonType == 0) {
        return "PYME";
      } else {
        if(this.ApplicantTaxProfile == 1) {
          return "PYME";
        }

        return "Personal";
      }
    },
    getLoanDesc() {
      if(this.UserPersonType == 0) {
        return `Crece tu negocio con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)}.`;
      } else {
        if(this.ApplicantTaxProfile == 1) {
          return `Crece tu negocio con hasta ${this.costFormatNoCents(this.LoanLimits.max_limit)}.`;
        }

        return `Te prestamos desde ${this.costFormatNoCents(this.LoanLimits.min_limit)} para mejorar tu vida y la del planeta.`;
      }
    },
    isPFAE() {
      return this.UserPersonType == 1 && this.ApplicantTaxProfile == 1;
    }
  },
  watch: {},
  async mounted() {
    this.isMounted = false;
    this.loading = true;
    await this.showLoading(true);
    // await this.getDataForHome();
    // await this.getIdentityInfo();
    // await this.verifyCompliances();
    await this.getLoans();
    this.isMounted = true;
    await this.showLoading(false);
    this.loading = false;
  },
  methods: {
    async getLoans() {
        try {
            const res = await axios.get(`api/loan-onboarding/get/${this.ApplicantId}/loans`);
            this.projects = res.data;
            this.isMounted = true;
        } catch (error) {
            console.log(error);
            this.failedOperationNotif("Algo salió mal.");
        }
    },
    getLoan(loanType, family_group_id = null) {
      if(loanType == 2 || loanType == 1) { // Sí es automotriz
        let loans = this.projects.filter(p => (p.loan_type == 1 || p.loan_type == 6)); 
        // console.log(loans);
        if(loans != null) {
          let currentLoan = null;
          loans.forEach(loan => {
            let fg_location = loan.fg_locations[0];
            if(fg_location && fg_location.family_group_id == family_group_id) {
              currentLoan = loan;
            }
          });
          return currentLoan;
        }
      } else if (loanType == 4) {  // Es PFAE y personal
        let type = 5; // Personal 
        const loan = this.projects.find(p => p.loan_type == type); 
        if(loan != null) {
          return loan;
        }
      } else if (loanType == 3) { // Sí es personal o pyme
        let type = 4; // Personal 
        // if(this.UserPersonType == 0 || (this.ApplicantTaxProfile == 1)) {
        //   type = 4 // PYME;
        // }
        const loan = this.projects.find(p => p.loan_type == type); 
        if(loan != null) {
          return loan;
        }
      }

      return null;
    },
    async getDataForHome(){
      try {
        let response = await axios.get(`api/v1/applicant/${this.ApplicantId}/getDataForHomePage`);
        this.allData = response.data;
        this.sharing.title = "Invierte en mi proyecto semilla '"+this.focusProject.alias+"' y crece tu dinero ¡mientras cambiamos el mundo!";
        this.sharing.quote = "Invierte en mi proyecto semilla '"+this.focusProject.alias+"' y crece tu dinero ¡mientras cambiamos el mundo!";
        this.clientInvoiceRequired = this.allData.clientInvoiceRequired;
        if (this.focusProject.status === 4) {
          await this.getFundingProjects();
          if (!this.focusProject.finance.credit_acceptance_date) {
            await this.getDeliveredProjects();
          }
        }
      }
      catch (e) {
        this.warn(e);
      }
    },
    async getFundingProjects(){
      try {
        const res = await axios.get('api/v1/public_stats/getFundingProjects');
        this.fundingProjects = res.data.slice(0,4);
      }
      catch (e) {
        console.log(e);
        this.warn(e);
      }
    },
    async getDeliveredProjects(){
      try {
        const res = await axios.get('api/v1/public_stats/getSuccessfulProjects');
        this.deliveredProjects = res.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    async getIdentityInfo(){
      try {
        this.status = await this.getUserIdentityStatus(this.UserId);
        this.identityIsCompleted = this.verificationIsCompleted(this.status);
      } catch (error) {
        console.log(error);
      }
    },
    async verifyCompliances(){
      try {
        // se verifica la identidad en general (ya sea p.fisica o p.moral)
        this.isMoral = this.userIsMoral(this.UserPersonType);
        let isForeign = this.userIsForeign(this.UserPersonType);
        // definir si mati esta pendiente o debe hacerse de nueva cuenta
        const shouldRunMati = this.shouldRunMatiProcess(this.status);
        if(shouldRunMati){
          this.mainComplianceStatus = "pending";
          return;
        }
        const matiIsRunning = this.matiIsRunning(this.status);
        if(matiIsRunning){
          this.mainComplianceStatus = "running";
          return;
        }
        // definir si esta completado
        const matiCompleted = this.verificationIsCompleted(this.status);
        if(matiCompleted){
          this.mainComplianceStatus = "completed";
        }
        else {
          this.mainComplianceStatus = "running";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async acceptCredit(){
      try {
        this.showLoading(true);
        let payload = {
          project_id: this.focusProject.id,
          contractType: this.contractApplicantMerchantType,
          client_invoice_required: this.clientInvoiceRequired
        };
        axios.post('/api/v1/applicant/'+this.ApplicantId+'/acceptCredit', payload).then(response => {
            this.showLoading(false);
            if (response.data != null) {
              this.acceptModalActive = true;
            }
        }).catch(error => {
            this.showLoading(false);
            Vue.toasted.clear()
            Vue.toasted.show('Parece que ha ocurrido un error, inténtalo de nuevo', { position: 'bottom-center', type:'error', closeOnSwipe: true})
        })
      }
      catch (e) {
        this.warn(e);
      }
    },
    async goToVerification(){
      this.acceptModalActive = false;
      await this.$router.push({name: 'perfilSolicitanteAbout'});
    },
    async goToPaymentOptions(){
      await this.$router.push({name: 'realizarPagosSolicitante'});
    },
    async goToApplicantSurvey(project){
      await this.$router.push({name: 'encuestaSatisfaccionSolicitante', params: { pid: project.id }});
    },
    async goToApplicantProjectDetail(project){
      await this.$router.push({name: 'detalleCreditoSolicitante', params: { id: project.id }});
    },
    async goToSignContracts(){
      await this.$router.push({name: 'contratosPendientesSolicitante', params: { pid: this.focusProject.id }});
    },
    async goToUploadCsds(){
      await this.$router.push({name: 'csd'});
    },
    async goToAutomaticPayments() {
     await this.$router.push({ name: 'pagoAutomaticoSolicitante' });
    },
    reloadPage(){
      this.$router.go();
    }
  },
};
</script>