export default {
    methods: {
        getCoverLoanType(loan_type){
            let placeholder = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-07.png";
            switch(loan_type) {
                case 1:
                   placeholder = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-07.png";
                  break;
                case 2:
                  placeholder = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-01.png";
                  break;
                case 3:
                  placeholder = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-09.png";
                  break;
                default:
                  placeholder = "https://cdn.redgirasol.com/green/PORTADAS_PROYECTOS/PORTADAS-07.png";
                  break;
              }
              return placeholder;
        },
        getNameLoanType(loan_type) {
          let projectName = "RedGirasol";
          switch(loan_type) {
              case 1:
                 projectName = "Eficiencia Energética";
                break;
              case 3:
                projectName = "Instaladores Solares";
                break;
              case 2:
                projectName = "Minado de Bitcoin";
                break;
              case 4:
                projectName = "Conservación";
                break;
              default:
                projectName = "RedGirasol";
                break;
            }
            return `Proyecto de ${projectName}`;
        },
    },
}