<!-- =========================================================================================
    File Name: ShareCard.vue
    Description: Card para compartir algo en redes sociales
========================================================================================== -->
<template>
    <div
        class="share-card fund mt-base"
        :style="{ backgroundImage: currentBg}" >
        <div class="notice-text text-5xl leading-min py-8" :class="variantClass">
            <slot name="text"></slot>
        </div>
        <div class="notice-button-container">
            <div class="flex flex-wrap" style="position: relative; padding:0 30%;">
              <share-network
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :url="url"
                  :title="sharing.title"
                  :description="sharing.description"
                  :quote="sharing.quote"
              >
                  <vs-button radius :color="network.color" class="ml-1" icon-pack="feather" :icon="network.icon"></vs-button>
              </share-network>
            </div>
        </div>
    </div>
</template>
<script lang="js">
export default  {
  name: 'ShareCard',
  props: {
    title: {
      type: String,
      required: true,
      default: 'En la Red el sol brilla para todos.',
    },
    description: {
      type: String,
      required: true,
      default: '',
    },
    quote: {
      type: String,
      required: true,
      default: '',
    },
    url: {
      type: String,
      required: false,
      default: 'https://www.redgirasol.com/',
    },
    variantClass: {
      type: String,
      required: true,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'dark', 'clear', 'green'].indexOf(value) !== -1
      }
    },
  },
  data() {
    return {
      primaryBg: `url(${require('@assets/images/pages/pattern01_sm_primary.jpg')})`,
      darkBg: `url(${require('@assets/images/pages/pattern01_sm_dark.jpg')})`,
      clearBg: `url(${require('@assets/images/pages/pattern01_sm_clear.jpg')})`,
      greenBg: `url(${require('@assets/images/pages/pattern01_sm_green.jpg')})`,
      currentBg: null,
      currentBtnColor: null,
        sharing: {
            url: 'https://www.redgirasol.com/',
            title: this.title,
            description: this.description,
            quote: this.quote,
        },
        networks: [
            { network: 'facebook', name: 'Facebook', icon: 'icon-facebook', color: '#1877f2' },
            { network: 'twitter', name: 'Twitter', icon: 'icon-twitter', color: '#1da1f2' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'icon-phone', color: '#25d366' },
        ],
    }
  },
  beforeMount() {
    this.autoSet();
  },
  methods: {
    autoSet(){
      switch (this.variantClass){
        case 'primary':
          this.currentBg = this.primaryBg;
          this.currentBtnColor = 'dark';
          break;
        case 'dark':
          this.currentBg = this.darkBg;
          this.currentBtnColor = 'primary';
          break;
        case 'clear':
          this.currentBg = this.clearBg;
          this.currentBtnColor = 'dark';
          break;
        case 'green':
          this.currentBg = this.greenBg;
          this.currentBtnColor = 'dark';
          break;
      }
    },
    async executeAction(){
      await this.$emit('on-button-action');
    }
  }
}
</script>
<style scoped lang="scss">
.share-card {
  max-width: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 5px 25px -10px #3b3a3e;
  line-height: normal;
  /* margin-bottom: 30px; */
  text-align: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  padding-top: 9rem !important;
  padding-bottom: 10rem !important;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.share-card .social-card-content{
  padding: 0 15px 15px 15px;
}
.share-card .social-card-content label{
  font-size: 14px;
  margin: 0;
}
.share-card h2{
  margin: 10px 0 0 0;
}
</style>