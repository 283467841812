<template>
    <div 
        :class="variantClass == 'blue' ? 'info-card_new_initial': 'info-card_new'"
        class="info-card lg:py-32 md:py-24 sm:py-12 py-6 mt-base"
        :style="{ backgroundImage: currentBg, backgroundColor: currentColor}">
        <div class="notice-text text-5xl leading-min py-8" :class="variantClass">
            <slot name="text"></slot>
        </div>
        <div v-if="needsButton" class="notice-button-container">
            <vs-button class="notice-button" :color="currentBtnColor" @click="executeAction">{{buttonText}}</vs-button>
        </div>
        <div v-else class="notice-button-container mt-4">
            <vs-button style="visibility:hidden" class="notice-button"></vs-button>
        </div>
    </div>
</template>

<script>
export default{
  name: 'InfoCard',
  props: {
    variantClass: {
      type: String,
      required: true,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'dark', 'clear', 'green', 'blue'].indexOf(value) !== -1
      }
    },
    buttonText: {
      type: String,
      default: 'texto',
      required: false,
    },
    needsButton: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {
      primaryBg: `url(${require('@assets/images/pages/makeover_green/GRAFICOS-08.png')})`,
      whiteBg: `url(${require('@assets/images/pages/makeover_green/GRAFICOS-07.png')})`,
      currentBg: null,
      currentBtnColor: null,
      currentColor: null,
    }
  },
  beforeMount() {
    this.autoSet();
  },
  methods: {
    autoSet(){
      switch (this.variantClass){
        case 'primary':
          this.currentColor = "#FFF";
          this.currentBtnColor = 'dark';
          this.currentBg = this.primaryBg;
          break;
        case 'dark':
          this.currentColor = '#3a393d';
          this.currentBtnColor = 'primary';
          this.currentBg = this.primaryBg;
          break;
        case 'clear':
          this.currentColor = "#d1d3d4";
          this.currentBtnColor = 'dark';
          this.currentBg = this.whiteBg;
          break;
        case 'green':
          this.currentColor = '#28DE18';
          this.currentBtnColor = 'dark';
           this.currentBg = this.whiteBg;
          break;
        case 'blue':
          this.currentColor = "#28DE18";
          this.currentBtnColor = 'dark';
          this.currentBg = this.whiteBg +",linear-gradient(to right, rgba(203,255,0,1) 70%, rgba(40,222,24,1) 100%)";
          break;
      }
    },
    async executeAction(){
      await this.$emit('on-button-action');
    }
  }
}
</script>

<style>

.info-card {
  max-width: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 5px 25px -10px #3b3a3e;
  line-height: normal;
  /* margin-bottom: 30px; */
  text-align: center;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
  padding-top: 9rem !important;
  padding-bottom: 10rem !important;
}

.notice-text {
  font-family: 'gilroyextrabold', sans-serif;
  text-align: center;
}

.notice-text.clear {
  color: rgba(var(--vs-dark), 1) !important;
}

.notice-text.dark {
  color: white !important;
}

.notice-text.primary,
.notice-text.green,
.notice-text.blue {
  color: #3b3a3e !important;
}

.notice-button-container {;
  text-align: center;
}

.notice-text.blue > span,
.notice-text.green > span,
.notice-text.clear > span {
  color: white !important;
}

.notice-text.primary > span,
.notice-text.dark > span {
  color: rgba(var(--vs-primary), 1) !important;
}

.notice-text.blue > span {
  color: rgba(var(--vs-rgwhite), 1) !important;
}

.info-card_new {
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-x: -38rem !important;
  background-size: 56rem !important;
  background-position-y: -10rem !important;
}

.info-card_new_initial {
  /* background-size: cover; */
  background-repeat: repeat-y;
  background-position-x: -38rem !important;
  background-size: 56rem !important;
  background-position-y: -10rem !important;
}
</style>